


















































































































































































































































































@media only screen and (max-width: 450px) {
  h1 {
    font-size: 20px !important;
  }
  .step {
    padding-top: 100px;
  }
}
